<template>
  <v-row>
    <v-col
      cols="12"
      class="text-right mb-2"
    >
      <v-btn
        small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.add_employee_payment' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Add Employee Payment
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card class="pt-2 mb-0 pb-0">
        <v-row class="pt-4 mx-2 pt-0">
          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getEmployeePayments()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="">
          <span class="me-3">EmployeePayments (<b v-if="employee_payments_total">{{ employee_payments_total }}</b> frs)</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="employee_paymentsList" item-key="id" class="table-rounded"
          :items-per-page="10" disable-sort :search="search">
          <!-- name -->
          <template #[`item.paid_employee_name`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.paid_employee_name }}</span>
              <small>Date: {{ item.get_month_year_str }}</small>
            </div>
          </template>
          <template #[`item.payment_means`]="{item}">
            {{ item.payment_means }}
          </template>
          <template #[`item.amount_paid`]="{item}">
            {{ item.amount_paid }} frs
          </template>
          <template #[`item.remaining_payment`]="{item}">
            {{ item.remaining_payment }} frs
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {
    return {
      headers: [
        { text: 'Employee', value: 'paid_employee_name' },
        { text: 'Operator', value: 'payment_means' },
        { text: 'Amount Paid', value: 'amount_paid' },
        { text: 'Amount Owing', value: 'remaining_payment' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      employee_paymentsList: [],
      search: '',
      selectedStartDate: '',
      selectedEndDate: '',
      employee_payments_total: '',
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  
  mounted(){

    // executes these after the page has been mounted
    document.title = "Manager: Savings | List of EmployeePayments"
  },
  
  methods:{
  async getEmployeePayments(){
    if (this.selectedStartDate === ''){
      this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      this.loading = true
      console.log(this.loading)
      await axios
      .get('/api/v1/manager/get/salary/payments/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.employee_paymentsList = response.data['salary_payments']  // get the data and fill into campaigns
          this.employee_payments_total = response.data['payments_total']
          console.log(this.employee_paymentsList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
